<template>
  <subview class="newcompetition-final">
    <view-content>
      <Finish 
        v-if="!finalBasketball"
        :background="true" 
        :title="$t('pages.newcompetition.final.title')" 
        :description="$t('pages.newcompetition.final.description')"
      />

       <Finish 
        v-if="finalBasketball"
        :background="true" 
        :title="$t('pages.newcompetition.final.titleBasketball')" 
        :description="$t('pages.newcompetition.final.descriptionBasketball')"
      />
    </view-content>
  </subview>
</template>

<script>

import Finish from '@/components/Finish'
import newcompetition from '@/mixins/newcompetition'

export default {
  name: 'Final',
  components: {
    Finish
  },
   mixins: [
    newcompetition
  ],
  computed: {
    finalBasketball(){
      return this.selectedSport?.name === "Basketball" ? true : false
    },
  },
  beforeRouteEnter (_, from, next) {
    if (from.name !== 'newcompetition.preview' ) {
      next({ name: 'competitions' })
    }
    
    next()
  }
}

</script>
